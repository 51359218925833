var menuLayout = [
//  Employers
    {
        id: 0,
        name:"Employers",
        description:"From our powerful EP Employer designation, to job funding for new hires, and custom-tailored research and HR services, we have the solutions to demonstrate your commitment as a champion of the Canadian environmental workforce.",
        link: "/environmental-employers/",
        columns:[
            {
                heading: "EP Employer Designation",
                link: "/environmental-employers/memberships-and-designations/ep-employer-designation/",
                children:[
                    {
                        name:"Application & Eligibility",
                        link: "/environmental-employers/memberships-and-designations/ep-employer-designation/ep-employer-application-eligibility/"
                    },
                    {
                        name:"Maintaining Your Status",
                        link: "/environmental-employers/memberships-and-designations/ep-employer-designation/maintaining-your-ep-employer-designation/"
                    },
                    {
                        name:"Apply Now",
                        link: "/environmental-employers/memberships-and-designations/ep-employer-designation/ep-employer-application-eligibility#eligibility"
                    },
                    {
                        name: 'Win an EP Employer Award',
                        link: 'https://ecoimpact.ca/'
                    },
                    {
                        name:"Specialization & Industry",
                        link: "/environmental-professionals/the-role-of-specializations-and-industry/"
                    },
                ]
            },
            {
                heading: "Custom HR Services",
                link: "/environmental-employers/hr-services/",
                children:[
                    {
                        name:"Managing People",
                        link: "/environmental-employers/hr-services/managing-people/"
                    },
                    {
                        name:"Recruiting People",
                        link: "/environmental-employers/hr-services/recruiting-people/"
                    },
                    {
                        name:"Job Board",
                        link: "/new-practitioners/employment-support/job-board/"
                    },
                    {
                        name:"Purchase Job Board Credits",
                        link: "/new-practitioners/employment-support/job-board/post-a-job/"
                    },
                ]
            },
            {
                heading: "Job & Training Funding",
                link: "/environmental-employers/employment-and-wage-subsidies/",
                children:[
                    {
                        name:"Compare Funding Programs",
                        link: "/environmental-professionals/employment-funding-and-job-board/apply-for-job-funding/"
                    },
                    {
                        name:"Start Your Application",
                        link: "/environmental-professionals/employment-funding-and-job-board/apply-for-job-funding/"
                    },
                    {
                        name:"Job Funding FAQs",
                        link: "/environmental-employers/employment-and-wage-subsidies/eco-canada-employment-programs-faqs/"
                    },
                ]
            },
            {
                heading: "Training For Your Team",
                link: "/environmental-employers/training-for-your-team/",
                children:[
                    {
                        name:"Become a BEAHR Trainer",
                        link: "/environmental-employers/training-for-your-team/beahr-indigenous-workforce-training/"
                    },
                    {
                        name:"Learning On-Demand",
                        link: "/learning-on-demand/"
                    },
                    {
                        name:"Open Licenses",
                        link: "/environmental-employers/eco-training-open-licenses/"
                    },
                    
                    {
                        name:"Live Learning",
                        link: "/live-learning/"
                    },
                    {
                        name:"Host a Webinar",
                        link: "/environmental-employers/training-for-your-team/host-a-webinar/"
                    },
                    
                ]
            },
            {
                heading: "Research & Resources",
                link: "/research-and-resources/",
                children:[
                    {
                        name:"Labour Market Information",
                        link: "/labour-market-research/"
                    },
                    {
                        name:"Occupational Standards",
                        link: "/nos-summary/"
                    },
                    {
                        name:"Report Catalogue",
                        link: "/labour-market-research/our-reports/"
                    },
                    {
                        name:"About The Environmental Workforce",
                        link: "/environmental-workforce-101/"
                    },
                    {
                        name:"Blog Articles",
                        link: "/blog/"
                    },
                    {
                        name: "Join Research Community",
                        link: "/educators/research-community/"
                    },
                    
                ]
            }
        ]    
    },
// Professionals
    {
        id: 1,
        name:"Professionals",
        description:"In conjunction with leading employers, academics & researchers, and established environmental practitioners, we’ve developed an extensive series of programs to help you grow your career.",
        link: "/environmental-professionals/",
        columns:[
            {
                heading: "Designations & Memberships​",
                link: "/about-us/programs-services/designations-memberships/",
                children:[
                    {
                        name:"Environmental Professional (EP)",
                        link: "/environmental-professionals/environmental-professional/"
                    },
                    {
                        name:"EP Auditor (EMSLA and CEA)",
                        link: "/environmental-professionals/ep-auditor/"
                    },
                    {
                        name: "Environmental Professional In-Training (EPt)",
                        link: "/environmental-professionals/environmental-professional-in-training/"
                    },
                    {
                        name:"Understanding Specialization in Industry",
                        link: "/environmental-professionals/the-role-of-specializations-and-industry/"
                    },
                    {
                        name: 'Ethics & Body of Knowledge',
                        link: '/environmental-professionals/ep-code-of-ethics/'
                    },
                    {
                        name: 'Mentorship',
                        link: '/environmental-professionals/mentorship/'
                    },
                    {
                        name: 'Member Roster',
                        link: 'https://eco.ca/EPRoster/'
                    },
                    {
                        name: 'Win an Impact Award',
                        link: 'https://ecoimpact.ca'
                    },
                    {
                        name: 'Annual Member Report',
                        link: 'https://info.eco.ca/acton/fs/blocks/showLandingPage/a/42902/p/p-0048/t/page/fm/0'
                    },
                    
                ]
            },
            {
                heading: "Employment Opportunities",
                link: "/environmental-professionals/employment-funding-and-job-board/",
                children:[
                    {
                        name:"Job Board",
                        link: "/new-practitioners/employment-support/job-board/"
                    },
                    {
                        name:"Apply for Job Funding",
                        link: "/environmental-professionals/employment-funding-and-job-board/apply-for-job-funding/"
                    },
                    {
                        name:"Funding Program FAQs",
                        link: "/environmental-employers/employment-and-wage-subsidies/eco-canada-employment-programs-faqs/"
                    },
                    
                ]
            },
            {
                heading: "Lifelong Learning",
                link: "/environmental-professionals/lifelong-learning-for-professionals/",
                children:[
                    {
                        name:"Live Learning",
                        link: "/live-learning/"
                    },
                    {
                        name:"Learning On-Demand",
                        link: "/learning-on-demand/"
                    },
                    {
                        name:"Continuing Education",
                        link: "/environmental-professionals/lifelong-learning-for-professionals/higher-education/"
                    },
                    {
                        name:"Become a BEAHR Trainer",
                        link: "/environmental-employers/training-for-your-team/beahr-indigenous-workforce-training/"
                    },
                ]
            },
            {
                heading: "Research & Resources",
                link: "/research-and-resources/",
                children:[
                    {
                        name:"Labour Market Information",
                        link: "/labour-market-research/"
                    },
                    {
                        name:"Occupational Standards",
                        link: "/nos-summary/"
                    },
                    {
                        name:"Report Catalogue",
                        link: "/labour-market-research/our-reports/"
                    },
                    {
                        name:"Resources Guide",
                        link: "/environmental-professionals/free-career-resources/"
                    },
                    {
                        name:"The Environmental Workforce",
                        link: "/environmental-workforce-101/"
                    },
                    {
                        name:"Blog Articles",
                        link: "/blog/"
                    },
                    {
                        name:"Join the Research Community",
                        link: "/educators/research-community/"
                    },
                    
                ]
            },
        ]    
    },
    // New Practitioners
    {
        id: 2,
        name:"New Practitioners",
        description:"Whether you’re just joining the environmental workforce or transitioning to a new environmental role within your industry, we help point your career in the right direction.",
        link: "/new-practitioners/",
        columns:[
            
            {
                heading: "BEAHR Indigenous Training",
                link: "/new-practitioners/beahr/",
                children:[
                    {
                        name:"Course Curriculum",
                        link: "/new-practitioners/beahr/bearh-course-curriculum/"
                    },
                    {
                        name:"In-Community Delivery",
                        link: "/new-practitioners/beahr/beahr-in-community-delivery/"
                    },
                    {
                        name:"Download the Program Guide",
                        link: "/new-practitioners/beahr/beahr-training-guide-download/"
                    },
                ]
            },
            {
                heading: "Employment Support",
                link: "/new-practitioners/employment-support/",
                children:[
                    {
                        name:"Job Board",
                        link: "/new-practitioners/employment-support/job-board/"
                    },
                    {
                        name:"Job Funding For Junior Roles",
                        link: "/environmental-professionals/employment-funding-and-job-board/apply-for-job-funding/"
                    },
                    {
                        name:"Job Funding FAQ",
                        link: "/environmental-employers/employment-and-wage-subsidies/eco-canada-employment-programs-faqs/"
                    },
                    {
                        name:"Virtual Career Fair",
                        link: "/virtual-career-fair/"
                    },
                ]
            },
            {
                heading: "Memberships & Designation",
                link: "/new-practitioners/memberships-designations/",
                children:[
                    {
                        name:"Environmental Professional in-training (EPt)",
                        link: "/environmental-professionals/environmental-professional-in-training/"
                    },
                    {
                        name:"Mentorship",
                        link: "/environmental-professionals/mentorship/"
                    },
                    {
                        name:"Member Roster",
                        link: "https://eco.ca/EProster"
                    },
                    {
                        name:"ECO+ Pro",
                        link: "/new-practitioners/eco-pro-membership-for-new-practitioners/"
                    },
                    
                ]
            },
            {
                heading: "Lifelong Learning",
                link: "/new-practitioners/lifelong-learning-for-new-practitioners/",
                children:[
                    {
                        name:"Essential Skills 200 Certificate",
                        link: "/online-learning/essential-skills-200/"
                    },
                    {
                        name:"Upcoming Live Learning",
                        link: "/live-learning/"
                    },
                    {
                        name:"Graduate Certificate in Policy & Climate Change",
                        link: "/environmental-professionals/lifelong-learning-for-professionals/higher-education/climate-change-certificate/"
                    },
                    {
                        name:"Masters in Environmental Practice",
                        link: "/environmental-professionals/lifelong-learning-for-professionals/higher-education/masters-in-environmental-practice-rru/"
                    },
                    {
                        name:"Explore Blog",
                        link: "/blog/"
                    },
                    
                ]
            },
            {
                heading: "Research & Resources",
                link: "/research-and-resources/",
                children:[
                    {
                        name: "About the Environmental Workforce",
                        link: "/environmental-students/environmental-workforce-101/"
                    },
                    {
                        name:"Occupational Standards",
                        link: "/nos-summary/"
                    },
                    {
                        name:"Labour Market Information",
                        link: "/research-and-resources/environmental-job-market-trends/"
                    },
                    {
                        name:"Career Research and Resources",
                        link: "/labour-market-research/our-reports/"
                    },                    
                ]
            },
        ]    
    },

    // Educators
    {
        id: 3,
        name:"Educators",
        description:"The competition for meaningful careers in the environmental labour force is more prevalent than ever. We want to assist educators in ensuring their environmental programs help graduates demonstrate an awareness of the environmental industry needs.",
        link: "/educators/",
        columns:[
            {
                heading: "Program Accreditation",
                link: "/educators/program-accreditation/",
                children:[
                    {
                        name:"How It Works",
                        link: "/educators/program-accreditation/how-it-works/"
                    },
                    {
                        name:"Download Application Guide",
                        link: "/educators/program-accreditation/current-canadian-programs#appguide"
                    },
                    {
                        name: "Program Benefits",
                        link: "/educators/program-accreditation/program-benefits/"
                    },
                    {
                        name:"Canadian Programs",
                        link: "/educators/program-accreditation/current-canadian-programs/"
                    }, 
                    {
                        name:"International Programs",
                        link: "/educators/program-accreditation/accrediting-international-programs/"
                    },
                    {
                        name:"Certify Your Training Courses",
                        link: "/educators/program-accreditation/training-certification/"
                    }, 
                    {
                       name: "The Canadian Environmental Accreditation Commission",
                       link: "/ceac-board/" 
                    }
                                      
                ]
            },
            {
                heading: "BEAHR Indigenous Training",
                link: "/new-practitioners/beahr/",
                children:[
                    {
                        name: "Articulation for Education Credit",
                        link: "/educators/beahr-articulation/"
                    },
                    {
                        name: "Explore Curriculum",
                        link: "/new-practitioners/beahr/bearh-course-curriculum/"
                    },
                    {
                        name:"Become a BEAHR Trainer",
                        link: "/environmental-employers/training-for-your-team/beahr-indigenous-workforce-training/"
                    },
                    
                ]
            },
            {
                heading: "Work Integrated Learning",
                link: "/educators/work-integrated-learning/",
                children:[
                    {
                        name:"Program & Application",
                        link: "/educators/work-integrated-learning#becomeAPartner"
                    },
                    {
                        name:"Virtual Career Fair",
                        link: "/virtual-career-fair/"
                    },
                ]
            },
            {
                heading: "Research & Resources",
                link: "/research-and-resources/",
                children:[
                    {
                        name:"Labour Market Information",
                        link: "/labour-market-research/"
                    },
                    {
                        name:"Occupational Standards",
                        link: "/nos-summary/"
                    },
                    {
                        name:"Report Catalogue",
                        link: "/labour-market-research/our-reports/"
                    },
                    {
                        name:"The Environmental Workforce",
                        link: "/environmental-students/environmental-workforce-101/"
                    },
                    {
                        name:"Join Our Research Community",
                        link: "/educators/research-community/"
                    },
                    
                ]
            },
        ]    
    },

    // Students
    {
        id: 4,
        name:"Students",
        description:"Through our partnerships with Canadian universities, government departments, and Canadian employers, we provide everything you need to take ownership of your career journey.",
        link: "/environmental-students/",
        columns:[
            {
                heading: "Career Preparedness Memberships",
                link: "/environmental-students/career-preparedness-membership/",
                children:[
                    {
                        name:"ECO+ Pro",
                        link: "/environmental-students/career-preparedness-membership/eco-pro-membership/"
                    },
                    {
                        name:"EPt Student",
                        link: "/environmental-students/career-preparedness-membership/ept-student/"
                    },                                        
                ]
            },
            {
                heading: "Student Job Funding",
                link: "/environmental-students/student-work-placement-program/",
                children:[
                    {
                        name:"Apply For Pre-Approval Status",
                        link: "/environmental-professionals/employment-funding-and-job-board/apply-for-job-funding/"
                    },
                    {
                        name:"Post-Secondary Partners",
                        link: "https://eco.ca/environmental-students/student-work-placement-program#partners"
                    },                                        
                    {
                        name:"Job Board",
                        link: "/new-practitioners/employment-support/job-board/"
                    },
                ]
            },            
            {
                heading: "Education & Job Readiness",
                link: "/environmental-students",
                children:[
                    {
                        name:"Become a BEAHR Graduate",
                        link: "/environmental-students/become-a-beahr-graduate/"
                    },
                    {
                        name:"Bachelors in Environmental Practice",
                        link: "/environmental-professionals/lifelong-learning-for-professionals/higher-education/bachelors-in-environmental-practice-rru/"
                    },
                    {
                        name:"Essential Skills for Job Seekers",
                        link: "/online-learning/essential-skills-for-job-seekers/"
                    },
                    {
                        name:"Virtual Career Fair",
                        link: "/virtual-career-fair/"
                    },
                    
                ]
            },
            {
                heading: "Lifelong Learning",
                link: "/environmental-students/lifelong-learning-for-students/",
                children:[
                    {
                        name:"On-Demand Webinars & Courses",
                        link: "/learning-on-demand/"
                    },
                    {
                        name:"Skills Employers Demand: Free Report",
                        link: "/new-reports/developing-skills-to-succeed-in-the-environmental-sector/"
                    },
                    {
                        name:"Attend ECO Impact",
                        link: "https://ecoimpact.ca"
                    },
                ]
            },
            {
                heading: "Accredited Post-Secondary Programs",
                link: "/educators/program-accreditation/current-canadian-programs",
                children:[
                    {
                        name:"Student Benefits",
                        link: "/environmental-students/accreditation/"
                    },
                    {
                        name:"Find an Accredited Environmental Program",
                        link: "/educators/program-accreditation/current-canadian-programs/"
                    },
                    {
                        name:"Get Designated Upon Graduation",
                        link: "/environmental-professionals/environmental-professional-in-training/",
                    },
                    {   name: "Sign Up for EPt Student",
                        link: "/environmental-students/career-preparedness-membership/ept-student/apply-now/"
                    },
                    
                ]
            },
            {
                heading: "Research & Resources",
                link: "/research-and-resources/",
                children:[
                    {
                        name:"The Environmental Workforce",
                        link: "/career-profiles/"
                    },
                    {
                        name:"Occupation Standards",
                        link: "/nos-summary/"
                    },
                    {
                        name:"Reports Catalogue",
                        link: "/labour-market-research/our-reports/"
                    },
                    {
                        name:"Career Materials",
                        link: "/environmental-professionals/free-career-resources/"
                    },
                    {
                        name:"Blog Articles",
                        link: "/blog/"
                    },
                    {
                        name:"Join the Research Community",
                        link: "/educators/research-community/"
                    },
                    
                ]
            },
            
        ]    
    },
    // About Us
    {
        id:5,
        name:"Who We Are",
        description:"From job creation and wage funding to training and labour market research, we champion the end-to-end career of an environmental professional.​",
        link: "/about-us/",
        columns:[
            {
                heading: "About Us",
                link: "/about-us",
                children:[
                    {
                        name:"Leadership",
                        link: "/about-us/leadership/"
                    },
                    {
                        name:"Partners",
                        link: "/about-us/leadership/partners/"
                    },
                    {
                        name:"Careers With Us",
                        link: "/about-us/leadership/careers/"
                    },    
                    {
                        name:"Giving Back",
                        link: "/about-us/leadership/giving-back/"
                    },    
                    {
                        name:"Advertise Your Organization",
                        link: "/about-us/leadership/advertise-your-organization/"
                    },    
                    {
                        name:"In The News",
                        link: "/blog/category/in-the-news/"
                    },    
                    {
                        name:"Contact Us",
                        link: "/about-us/leadership/contact-us/"
                    },                                            
                ]
            },
            {
                heading: "Programs & Services",
                link: "/about-us/programs-services/",
                children:[
                    {
                        name:"HR & Employment",
                        link: "/environmental-employers/hr-services/",
                        bold: true
                    },
                    {
                        name:"Wage Subsidy Programs",
                        link: "/environmental-professionals/employment-funding-and-job-board/"
                    },                                        
                    {
                        name:"Wage Subsidy FAQs",
                        link: "/environmental-employers/employment-and-wage-subsidies/eco-canada-employment-programs-faqs/"
                    },
                    {
                        name: "Recruiting People",
                        link: "/environmental-employers/hr-services/recruiting-people/"
                    },
                    {
                        name: "ECO Job Board",
                        link: "/new-practitioners/employment-support/job-board/"
                    },
                    {
                        name: "Managing People",
                        link: "/environmental-employers/hr-services/managing-people/"
                    },
                ]
            },            
            {
                heading: "Lifelong Learning",
                link: "/environmental-professionals/lifelong-learning-for-professionals/ ",
                children:[
                    {
                        name:"BEAHR Indigenous Training",
                        link: "/new-practitioners/beahr/"
                    },
                    {
                        name:"On-Demand Learning",
                        link: "/learning-on-demand/"
                    },
                    {
                        name:"Live Learning",
                        link: "/live-learning/"
                    },
                    {
                        name:"ECO Impact",
                        link: "https://ecoimpact.ca"
                    },
                    {
                        name:"Higher Education",
                        link: "/environmental-professionals/lifelong-learning-for-professionals/higher-education/"
                    },
                    
                ]
            },
            {
                heading: "Memberships",
                link: "/about-us/programs-services/designations-memberships/",
                children:[
                    {
                        name:"EP Designation",
                        link: "/about-us/programs-services/designations-memberships/"
                    },
                    {
                        name:"EP Auditor Designation",
                        link: "/environmental-professionals/ep-auditor/"
                    },
                    {
                        name:"EP in-training Designation",
                        link: "/environmental-professionals/environmental-professional-in-training/"
                    },
                    {
                        name:"ECO+ Pro Membership",
                        link: "/environmental-students/career-preparedness-membership/eco-pro-membership/"
                    },
                ]
            },
            {
                heading: "Accreditation & Training Certification",
                link: "/educators/program-accreditation/ ",
                children:[
                    {
                        name:"Canadian Post-Secondary Programs",
                        link: "/educators/program-accreditation/current-canadian-programs/"
                    },
                    {
                        name:"International Accreditation",
                        link: "/educators/program-accreditation/accrediting-international-programs/"
                    },
                    {
                        name:"Training Certification",
                        link: "/educators/program-accreditation/training-certification/"
                    },
                ]
            },
            {
                heading: "Research & Resources",
                link: "/research-and-resources/",
                children:[
                    {
                        name:"Labour Market Information",
                        link: "/labour-market-research/"
                    },
                    {
                        name:"Occupational Standards",
                        link: "/nos-summary/"
                    },
                    {
                        name:"Reports Catalogue",
                        link: "/labour-market-research/our-reports/"
                    },
                    {
                        name:"Career Materials",
                        link: "/environmental-professionals/free-career-resources/"
                    },
                    {
                        name:"Blog Articles",
                        link: "/blog/"
                    },
                    
                ]
            },
           
        ]    
    },    
]

export default menuLayout;