<template>

  <section id="eco-sitemap" >

    <!-- sitemap -->

    <b-container 
    fluid class="eco-sitemap-panel">


      <b-row v-for="menu in this.menuData" :key="menu.id">
        <!-- Nav Items -->
         <b-col class="eco-sitemap-content">
            <b-container fluid class="p-4">
              <b-row>
                <b-col class="border-bottom mb-2">
                  <h2 class="eco-sitemap-description-title">
                    {{menu.name}}</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" sm="3" v-for="column in menu.columns" :key="column.heading" class="eco-sitemap-col">
                  <b-link :href="column.link" class="eco-sitemap-column-heading">{{column.heading}}</b-link>
                  <ul>
                    <li v-for="child in column.children" :key="child.name" class="eco-sitemap-column-child">
                      <div class="mt-3" v-if="child.bold">
                        <b-link :href="child.link" class="font-weight-bold" >{{child.name}}</b-link>
                      </div>
                     <b-link v-else :href="child.link">{{child.name}}</b-link> 
                    </li>
                  </ul>
                </b-col>
              </b-row>

            </b-container>
         </b-col>

      </b-row>

    </b-container>




  
  </section>
</template>

<script>
import Vue from 'vue'

import {BootstrapVue,LayoutPlugin, LinkPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
Vue.use(BootstrapVue)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
// Vue.use(IconsPlugin)

// Debounce
import { debounce } from "debounce";
Vue.use(debounce)

import menuLayout from './menu.js'
import menuLayoutFr from './menu-fr.js'


// import axios from 'axios'


export default {
  name: 'EcoSitemap',
  created:function(){
    console.log('init sitemap:')
    //check current language
    var c = this.getCookie('pll_language')
    //check if dotnet - <head> has ID
    var isDotNET = document.querySelector('head').id.length > 0
    console.log('begin by checking sitemap for lang cookie:',c)
    if(document.querySelector('html').getAttribute('lang') && !isDotNET){
      // console.log('checking for html language attr')
      if(document.querySelector('html').getAttribute('lang').includes('fr')){
        this.activeLanguage = 'fr'
      }else{
        this.activeLanguage = 'en'
      }
   
    }else if(c){
      this.activeLanguage = c;
    }else {
      // else set to english - user probably came to login page directly without hitting WP first
      this.activeLanguage = 'en';
    }
    console.log(this.activeLanguage)

     // get from js file
     if(this.activeLanguage == "en"){
       this.menuData = menuLayout
     }else{
       this.menuData = menuLayoutFr
     }
    console.log(this.menuData)

    // assign ids to individual columns
    this.menuData.forEach(function(v){
      // console.log(i,v)
      v.columns.forEach(function(v2,i2){
        v2.id = i2
      })
    })


  },
  methods:{
    
    getCookie:function(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

  },
  computed:{
    
  },
  components: {
    
  },
  data(){
    return{
      menuData:null,
      activeID: 0,
      navHover: false,
      panelHover: false,
      showsitemap: false,
      isSignedIn: false,
      // language
      activeLanguage: null,
    }
  }
}
</script>

<style>

#eco-sitemap {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* z-index: 1000; */
  height: 100%;
  max-width:min(1024px, auto);
  /* position: relative; */
  /* padding-bottom:100%; */
  /* overflow-y: scroll; */

}


/* desktop */

  .eco-nav-item{
      /* flex:0 0 auto; */
      align-items:center;
      justify-content: center;
      text-align: center;
      width:auto;
      border: 1px solid transparent;
  }

  .eco-nav-items a{
    min-height: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: min(1rem,0.8vw);
    color: #2d2d2d;
    margin: auto 0;
    text-decoration: none;
    transition: background-color .1s ease-in-out;

    /* transition: background-color .2s ease-in; */
  }

  .eco-nav-item:first-of-type{
    margin-left:15px;
  }



  .eco-nav-item:hover, .ECONavBtn:hover{
  background-color: rgba(0, 0, 0, 0.022);
  border: 1px solid rgba(0, 0, 0, 0.042);
  text-decoration: none;
  cursor: pointer;
}
.eco-nav-item:hover:first-of-type{
  border-left-color: transparent;
}
.eco-nav-item:hover:last-of-type{
  border-right-color: transparent;

}

.ECONavBtn a div{
  font-size: min(1rem,0.9vw);
}



.eco-logo-img{
  max-height: 45px;
  min-width: 100%;
}
.eco-logo-text{
  font-size: min(0.8vw,12px);
  color: #2d2d2d;
  font-weight:300 !important;
}
.eco-nav-items{
  display: flex;
  /* justify-content: space-evenly; */
  width: 100%;
  align-items:flex-start ;
}


.eco-sitemap-panel{
  font-size:0.8rem;
  text-align: left;
  height: auto;
  backdrop-filter: opacity(0.1); /* not sure why this affects the :before div*/
  padding:2vh 0px;
  z-index:500;
 
  /* new */
  max-width: min(1200px,100vw);
  min-height:450px;
  margin: 60px auto 0;
}
@media screen and (max-width:1024px){
  .eco-sitemap-panel{
    margin: 0px auto;
  }
  section#eco-sitemap{
    padding: 0 !important;
  }
}


.eco-sitemap-panel .eco-sitemap-column-child a,
a.eco-sitemap-column-heading{
  color: #2d2d2d;
  max-width: 80%;
  display: block;
}
.eco-sitemap-panel a:hover{
  color: #3A9B42;
  text-decoration: none;
}
.eco-sitemap-panel ul{
  padding: 0;
  margin-top: 10px;
}

/* description */
.eco-sitemap-description-title{
  font-size:1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.eco-sitemap-description-title a{
  font-family: 'Open Sans';
  color: #2d2d2d;

}
.eco-sitemap-description-inner{
  background: #fefefe;
  border: 1px solid #2d2d2d;
  padding: 25px;
  margin: 15px;
  box-shadow: 0 0 10px -5px rgba(0,0,0,0.30);
  transition: box-shadow .3s linear;
}
.eco-sitemap-description-inner:hover{
  box-shadow: 0 0 20px -10px rgba(0,0,0,0.70);
}
.eco-sitemap-description-inner, .eco-nav-item{
    line-height: 1.5;
}


/* links */

.eco-sitemap-col{
  padding-top:25px;
  animation: .23s fadeInOpacity ease-in;
}
@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
  50%{
    opacity: .25;
  }
  75%{
    opacity:.6;
  }
	100% {
		opacity: 1;
	}
}
.eco-sitemap-column-heading{
  font-weight: bold;
  margin-bottom: .4rem;
  line-height: 1.5;
}
.eco-sitemap-column-child{
  list-style: none;
  margin-bottom: 10px;
  line-height: 1.2
}
 


</style>
