var menuLayoutFr = [
    //  Employeurs
        {
            id: 0,
            name:"Employeurs",
            description:"Du financement d’emploi pour les nouveaux employés à la recherche sur le marché du travail et à la formation pour les membres de votre équipe, nous avons les solutions pour soutenir votre main-d’œuvre environnementale.",
            link: "/employeurs/",
            columns:[
                {
                    heading: "Employeur d’EP",
                    link: "/employeurs/designation-employeur-ep/",
                    children:[
                        {
                            name:"Demande et admissibilité",
                            link: "/employeurs/designation-employeur-ep/admissibilite-de-la-demande-de-designation-employeur-ep/"
                        },
                        {
                            name:"FAQ",
                            link: "/employeurs/designation-employeur-ep/faq-sur-les-programmes-demploi-deco-canada/"
                        },
                        {
                            name:"Maintenir votre statut",
                            link: "/employeurs/designation-employeur-ep/conserver-votre-designation-employeur-ep/"
                        },
                        {
                            name:"Faites une demande dès maintenant",
                            link: "/employeurs/designation-employeur-ep/admissibilite-de-la-demande-de-designation-employeur-ep/#quiz/"
                        },
                        {
                            name: "Gagnez un prix d'employeur EP",
                            link: 'https://ecoimpact.ca/fr/'
                        },
                        {
                            name:"Spécialisée dans le secteur de l'environnment",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/specialisee-dans-le-secteur-de-lenvironnement/"
                        },
                    ]
                },
                {
                    heading: "Opportunités de recrutement",
                    link: "",
                    children:[
                        {
                            name:"Site d'annonce d'emplois",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/tableau-des-emplois/#/"
                        },
                        {
                            name:"Achat de crédits d'emploi",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/tableau-des-emplois/afficher-un-emploi/"
                        },
                        {
                            name:"Comparez les programmes d'aide financière",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/financement-en-matiere-demploi-et-tableau-des-emplois/presenter-une-demande-de-financement-en-matiere-demploi/"
                        },
                        {
                            name:"FAQ sur le financement des emplois",
                            link: "/employeurs/designation-employeur-ep/faq-sur-les-programmes-demploi-deco-canada/"
                        },
                    ]
                },
                {
                    heading: "Formation pour votre équipe",
                    link: "/employeurs/formation-pour-votre-equipe/",
                    children:[
                        {
                            name:"Devenez un formateur BEAHR",
                            link: "/employeurs/formation-pour-votre-equipe/formation-de-la-main-doeuvre-autochtone-du-beahr/"
                        },
                        {
                            name:"Apprentissage sur demande",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-sur-demande/"
                        },
                        {
                            name:"Compétences essentielles",
                            link: "/online-learning/certificat-200-competences-essentielles/"
                        },
                        {
                            name:"Apprentissage en direct",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-en-direct/"
                        },
                        {
                            name:"Téléchargement de la recherche UES",
                            link: "/#/"
                        },
                        {
                            name:"Licences libres",
                            link: "/employeurs/formation-pour-votre-equipe/licences-ouvertes-en-formation-eco/"
                        },
                        {
                            name:"Impact ECO",
                            link: "https://ecoimpact.ca/fr/"
                        },
                    ]
                },
                {
                    heading: "Recherche et ressources",
                    link: "/recherche-et-ressources/",
                    children:[
                        {
                            name:"Profils de carrière",
                            link: "/profils-de-carriere/"
                        },
                        {
                            name:"Téléchargez NOS / Profils de compétences",
                            link: "/recherche-et-ressources/resume-des-npn/"
                        },
                        {
                            name:"Recherche sur le marché du travail",
                            link: "/recherche-et-ressources/recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Blogue",
                            link: "/recherche-et-ressources/blogue/"
                        },
                    ]
                }
            ]    
        },
    // Professionnels
        {
            id: 1,
            name:"Professionnels",
            description:"Mettez en valeur votre expertise en tant que chef de file et responsable de la main-d’œuvre environnementale. En collaboration avec des employeurs, des universitaires et des chercheurs spécialistes, ainsi qu’avec des praticiens de l’environnement reconnus, nous avons mis au point une série complète de programmes afin d’aider des personnes comme vous à développer leur carrière. ",
            link: "/professionnels/",
            columns:[
                {
                    heading: "Désignation professionnelle et adhésion",
                    link: "/professionnels/designations-adhesions/",
                    children:[
                        {
                            name:"Professionnel de l'environnement (EP)",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/"
                        },
                        {
                            name:"Auditeur EP (EMSLA et CEA)",
                            link: "/professionnels/adhesions-et-designations/auditeur-ep/"
                        },
                        {
                            name: "Professionnel de l'environnement en formation (EPt)",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement-en-formation/"
                        },
                        {
                            name: 'Code d\'éthique du EP',
                            link: '/professionnels/adhesions-et-designations/code-dethique-ep/'
                        },
                        {
                            name: 'Programme de mentorat EP',
                            link: '/professionnels/adhesions-et-designations/programme-de-mentorat-ep/'
                        },
                        {
                            name: 'Régistre EP',
                            link: 'https://eco.ca/EPRoster/'
                        },
                        {
                            name: 'Gagnez un prix Impact',
                            link: 'https://ecoimpact.ca'
                        },
                        {
                            name: 'Rapport annuel des membres',
                            link: '/free-resources/rapport-des-membre-ep-2020/'
                        },
                        
                    ]
                },
                {
                    heading: "Professionnel de l'environnement",
                    link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/",
                    children:[
                        {
                            name:"FAQ",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/foire-aux-questions-sur-la-designation-ep/"
                        },
                        {
                            name:"Guide de demande et d'examen",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/candidature-ep-et-guide-dexamen/"
                        },
                        {
                            name:"Gestion des membres",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/maintien-des-membres-ep/"
                        },
                        {
                            name:"Spécialisations et Secteur",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/specialisee-dans-le-secteur-de-lenvironnement/"
                        },
                        {
                            name: "Auditeur EP (EMSLA) et (CEA)",
                            link: "/professionnels/adhesions-et-designations/auditeur-ep/", 
                            bold: true
                        },
                        {
                            name:"Guide d’examen à l’intention de l’auditeur EP",
                            link: "/professionnels/adhesions-et-designations/auditeur-ep/guide-de-candidature-et-dexamen-ep-auditeur/"
                        },
    
                    ]
                },
                
                {
                    heading: "Professionnel de l'environnement en formation",
                    link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement-en-formation/",
                    children:[
                        {
                            name:"Gestion des membres",
                            link: "/etudiants/accreditation/etudiant-ept/candidature-etudiante-ept/"
                        },
                        {
                            name: "Comprendre la spécialisation",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/specialisee-dans-le-secteur-de-lenvironnement/",
                            bold: true
                        },
                        {
                            name: "Mentorat",
                            link: "/professionnels/adhesions-et-designations/programme-de-mentorat-ep/",
                            bold: true
                        },
                        {
                            name: "Gagnez un prix Impact",
                            link: "https://ecoimpact.ca/fr/",
                            bold: true
                        },
                        
                    ]
                },
                
                {
                    heading: "Emploi et financement des emplois",
                    link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/financement-en-matiere-demploi-et-tableau-des-emplois/",
                    children:[
                        {
                            name:"Site d'annonce d'emplois",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/tableau-des-emplois/#/"
                        },
                        {
                            name:"Aperçu des programmes et de la demande",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/financement-en-matiere-demploi-et-tableau-des-emplois/presenter-une-demande-de-financement-en-matiere-demploi/"
                        },
                        {
                            name:"FAQ",
                            link: "/employeurs/designation-employeur-ep/faq-sur-les-programmes-demploi-deco-canada/"
                        },
                        {
                            name: "Apprentissage permanent",
                            link: "/professionnels/apprentissage-tout-au-long-de-la-vie/",
                            bold: true,
                        },
                        {
                            name:"Apprentissage en direct",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-en-direct/"
                        },
                        {
                            name:"Apprentissage sur demande",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-sur-demande/"
                        },
                    ]
                },
                
                {
                    heading: "Recherche et ressources",
                    link: "/recherche-et-ressources/",
                    children:[
                        {
                            name:"Les fondements du secteur",
                            link: "/recherche-et-ressources/main-doeuvre-environnementale-101/"
                        },
                        {
                            name:"Profils de carrière",
                            link: "/profils-de-carriere/"
                        },
                        {
                            name:"Rapports de recherche",
                            link: "/recherche-et-ressources/catalogue-des-rapports-de-recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Blogue",
                            link: "/recherche-et-ressources/blogue/"
                        },
                    ]   
                }
            ]
        },
        // Nouveaux Praticiens
        {
            id: 2,
            name:"Nouveaux Praticiens",
            description:"Que vous veniez de vous joindre à la main-d’œuvre du secteur de l’environnement ou que vous soyez en transition vers un nouveau rôle environnemental au sein de votre secteur, nous pouvons vous aider à orienter votre carrière dans la bonne direction.",
            link: "/nouveaux-praticiens/",
            columns:[
                
                {
                    heading: "BEAHR",
                    link: "/nouveaux-praticiens/beahr-fr/",
                    children:[
                        {
                            name:"Cuuriculum du cours",
                            link: "/nouveaux-praticiens/beahr-fr/programme-de-cours-beahr/"
                        },
                        {
                            name:"Téléchargez le guide du programme",
                            link: "/nouveaux-praticiens/beahr-fr/telecharger-le-guide-des-programmes-de-formation-beahr/"
                        },
                        {
                            name:"Devenez un formateur accrédité",
                            link: "/employeurs/formation-pour-votre-equipe/formation-de-la-main-doeuvre-autochtone-du-beahr/"
                        },
                        {
                            name:"Prestation au sein de la communauté",
                            link: "/nouveaux-praticiens/beahr-fr/formation-beahr-dans-votre-communaute"
                        },
                    ]
                },
                {
                    heading: "Emplois et aide financière aux emplois",
                    link: "/nouveaux-praticiens/soutien-a-lemploi/",
                    children:[
                        {
                            name:"Site d'annonce d'emploi",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/tableau-des-emplois/#/"
                        },
                        {
                            name:"Aperçu et demande du programme de financement salarial",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/financement-en-matiere-demploi-et-tableau-des-emplois/presenter-une-demande-de-financement-en-matiere-demploi/"
                        },
                        {
                            name:"FAQ",
                            link: "/employeurs/designation-employeur-ep/faq-sur-les-programmes-demploi-deco-canada/"
                        },
                    ]
                },
                {
                    heading: "Désignation professionnelle et adhésion",
                    link: "/nouveaux-praticiens/adhesions-et-designations/",
                    children:[
                        {
                            name:"Professionnel de l'environnement en formation",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement-en-formation/"
                        },
                        {
                            name:"Maintenir votre statut",
                            link: "/etudiants/accreditation/etudiant-ept/candidature-etudiante-ept/"
                        },
                        {
                            name:"Mentorat",
                            link: "/professionnels/adhesions-et-designations/programme-de-mentorat-ep/"
                        },
                        {
                            name:"Gagnez un prix Impact",
                            link: "https://ecoimpact.ca/fr/"
                        },
                        {
                            name:"Code de déontologie",
                            link: "/professionnels/adhesions-et-designations/code-dethique-ep/"
                        },
                        {
                            name:"Apprentissage en direct",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-en-direct/"
                        },
                        {
                            name:"Apprentissage sur demande",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-sur-demande/"
                        },
                        {
                            name:"Compétences essentielles",
                            link: "/online-learning/certificat-200-competences-essentielles/"
                        },
                        
                    ]
                },
                {
                    heading: "Recherche et ressources",
                    link: "/recherche-et-ressources/",
                    children:[
                        {
                            name:"Les fondements du secteur",
                            link: "/recherche-et-ressources/main-doeuvre-environnementale-101/"
                        },
                        {
                            name:"Profils de carrière",
                            link: "/profils-de-carriere/"
                        },
                        {
                            name:"Rapports sur le marché du travail",
                            link: "/recherche-et-ressources/recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Rapports de recherche",
                            link: "/recherche-et-ressources/catalogue-des-rapports-de-recherche-sur-le-marche-du-travail/"
                        },
                    ]
                },
            ]    
        },
    
        // Éducateurs
        {
            id: 3,
            name:"Éducateurs",
            description:"La concurrence pour les carrières valorisantes dans le secteur de l'environnement est plus forte que jamais. ECO Canada veut soutenir vos programmes axés sur l'environnement à dépasser ces exigences rigoureuses et aider vos diplômés à faire preuve d'une sensibilisation aux besoins du secteur environnemental.",
            link: "/educateurs/",
            columns:[
                {
                    heading: "Accréditation de programme",
                    link: "/educateurs/accreditation-des-programmes/",
                    children:[
                        {
                            name:"Comment cela fonctionne",
                            link: "/educateurs/accreditation-des-programmes/comment-ca-fonctionne/"
                        },
                        {
                            name:"Guide de demande",
                            link: "/educateurs/accreditation-des-programmes/avantage-du-programme/#forme"
                        },
                        {
                            name:"Certifiez votre cours de formation",
                            link: "/educateurs/accreditation-des-programmes/accreditation-de-vos-cours-de-formation/"
                        },
                        {
                            name: "Avantages du programme",
                            link: "/educateurs/accreditation-des-programmes/avantage-du-programme/"
                        },
                        {
                            name:"Programmes canadiens actuels",
                            link: "/educateurs/accreditation-des-programmes/programmes-canadiens-actuels/"
                        }, 
                        {
                            name:"Accréditation des établissements postsecondaires internationaux",
                            link: "/educateurs/accreditation-des-programmes/accreditation-des-programmes-internationaux/"
                        },
                    ]
                },
                {
                    heading: "Programme autochtone BEAHR",
                    link: "/nouveaux-praticiens/beahr-fr/",
                    children:[
                        {
                            name: "Accord d'articulation BEAHR",
                            link: "/educateurs/articulation-beahr/"
                        },
                        {
                            name:"Cuuriculum du cours",
                            link: "/nouveaux-praticiens/beahr-fr/programme-de-cours-beahr/"
                        },
                        {
                            name:"Téléchargez le guide du programme",
                            link: "/nouveaux-praticiens/beahr-fr/telecharger-le-guide-des-programmes-de-formation-beahr/"
                        },
                        {
                            name:"Devenez un formateur BEAHR",
                            link: "/employeurs/formation-pour-votre-equipe/formation-de-la-main-doeuvre-autochtone-du-beahr/"
                        },
                    ]
                },
                {
                    heading: "Apprentissage intégré au travail",
                    link: "/educateurs/apprentissage-integre-au-travail//",
                    children:[
                        {
                            name:"Découvrez l'aide financière et faites une demande",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/financement-en-matiere-demploi-et-tableau-des-emplois/presenter-une-demande-de-financement-en-matiere-demploi/"
                        },
                    ]
                },
                {
                    heading: "Recherche et ressources",
                    link: "/recherche-et-ressources/",
                    children:[
                        {
                            name:"Les fondements du secteur",
                            link: "/recherche-et-ressources/main-doeuvre-environnementale-101/"
                        },
                        {
                            name:"Profils de carrière",
                            link: "/profils-de-carriere/"
                        },
                        {
                            name:"Rapports sur le marché du travail",
                            link: "/recherche-et-ressources/recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Rapports de recherche",
                            link: "/recherche-et-ressources/catalogue-des-rapports-de-recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Devenez un  réviseur de sites",
                            link: "/educateurs/devenez-un-evaluateur-de-site/"
                        },
                        
                    ]
                },
            ]    
        },
    
        // Étudiants
        {
            id: 4,
            name:"Étudiants",
            description:"Nous avons mis au point une vaste gamme de programmes, de services et de formation continue afin de vous aider à prendre une longueur d’avance sur votre future carrière. Nous avons établi des partenariats avec des universités canadiennes, des ministères et des employeurs canadiens afin de vous fournir tout ce dont vous avez besoin pour prendre en main votre parcours professionnel.",
            link: "/etudiants/",
            columns:[
                {
                    heading: "Adhésion de préparation à la carrière",
                    link: "/etudiants/accreditation/adhesion-a-la-preparation-a-la-carriere/",
                    children:[
                        {
                            name:"ECO+ Pro",
                            link: "/nouveaux-praticiens/adhesion-eco-pro/"
                        },
                    ]
                },
                {
                    heading: "Aide financière des emplois d'étudiants",
                    link: "/etudiants/programme-de-stages-pour-etudiants/",
                    children:[
                        {
                            name:"Demande étudiante",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/financement-en-matiere-demploi-et-tableau-des-emplois/presenter-une-demande-de-financement-en-matiere-demploi/"
                        },
                        {
                            name:"Partenaires post-secondaires",
                            link: "/etudiants/programme-de-stages-pour-etudiants#partners"
                        },                                        
                    ]
                },            
                {
                    heading: "Éducation et préparation à l'emploi",
                    link: "/etudiants/",
                    children:[
                        {
                            name:"Devenez un diplômé BEAHR",
                            link: "/etudiants/obtenir-un-diplome-du-beahr/"
                        },
                        {
                            name:"Compétences essentielles pour les chercheurs d'emploi",
                            link: "/online-learning/les-competences-essentielles-pour-les-chercheurs-demploi/"
                        },
                        {
                            name:"Apprentissage en direct",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-en-direct/"
                        },
                        {
                            name:"Apprentissage sur demande",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-sur-demande/"
                        },
                        {
                            name:"Site d'annonce d'emplois",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/tableau-des-emplois/#/"
                        },
                        
                    ]
                },
                
                {
                    heading: "Recherche et ressources",
                    link: "/recherche-et-ressources/",
                    children:[
                        {
                            name:"Industrie 101",
                            link: "/recherche-et-ressources/main-doeuvre-environnementale-101/"
                        },
                        {
                            name:"Profils de carrière",
                            link: "/profils-de-carriere/"
                        },
                        {
                            name:"Rapports sur le marché du travail",
                            link: "/recherche-et-ressources/recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Rapports de recherche",
                            link: "/recherche-et-ressources/catalogue-des-rapports-de-recherche-sur-le-marche-du-travail/"
                        },
                    ]
                },
                {
                    heading: "Accréditation",
                    link: "/educateurs/accreditation-des-programmes/",
                    children:[
                        {
                            name:"Avantages aux étudiants",
                            link: "/etudiants/accreditation/avantages-pour-les-etudiants-ept/"
                        },
                        {
                            name:"Trouvez un programme environnemental accrédité",
                            link: "/educateurs/accreditation-des-programmes/programmes-canadiens-actuels/"
                        },
                        {
                            name:"Obtenez votre désignation à l'obtention du diplôme",
                            link: "/etudiants/accreditation/etudiant-ept/",
                        },
                    ]
                },
                
            ]    
        },
        // À propos
        {
            id:5,
            name:"À propos",
            description:"De la création d’emplois et de la subvention salariale à la formation et à la recherche sur le marché du travail – nous défendons l’ensemble de la carrière d’un professionnel de l’environnement. ",
            link: "/a-propos/",
            columns:[
                {
                    heading: "Campagnes d'image",
                    link: "/a-propos/chemins-de-connexion/",
                    bold: true,
                    children: [
                        {
                            name: "Leadership",
                            link: "/a-propos/leadership/",
                            bold: true
                        },
                        {
                            name: "Carrières chez ECO",
                            link: "/a-propos/carrieres/",
                            bold: true
                        },
                        {
                            name:"Blogue",
                            link: "/recherche-et-ressources/blogue/",
                            bold: true
                        },
                        {
                            name: "Dans l'actualité",
                            link: "/recherche-et-ressources/blogue/",
                            bold: true
                        },
                        {
                            name: "Partenaires",
                            link: "/a-propos/partenaires/",
                            bold: true
                        },
                        {
                            name: "Redonner à la communauté",
                            link: "/a-propos/redonner/",
                            bold: true
                        },
                        {
                            name:"Entrez en contact avec nous",
                            link: "/a-propos/contactez-nous/",
                            bold: true
                        },
                        {
                            name:"Programmes et services",
                            link: "/a-propos/programmes-et-services/",
                            bold: true
                        },
                    ]
                },
               
                
                {
                    heading: "Opportunités de recrutement",
                    link: "#",
                    children:[
                        {
                            name: "Programmes de subventions salariales",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/",
                            bold: true
                        },
                        {
                            name:"FAQ sur les subventions salariales",
                            link: "/employeurs/designation-employeur-ep/faq-sur-les-programmes-demploi-deco-canada/"
                        },                                        
                        {
                            name:"Site d'annonce d'emplois",
                            link: "/professionnels/designations-adhesions/emplois-et-subventions-salariales/tableau-des-emplois/"
                        }
                    ]
                },            
                {
                    heading: "Désignations",
                    link: "/professionnels/designations-adhesions/",
                    children:[
                        {
                            heading: "Désignation EP",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement/",
                        },
                        {
                            heading: "Désignation d'auditeur EP",
                            link: "/professionnels/adhesions-et-designations/auditeur-ep/",
                        },
                        {
                            heading: "Désignation EP en formation",
                            link: "/professionnels/adhesions-et-designations/professionnel-de-lenvironnement-en-formation/",
                        },
                        {
                            heading: "Désignation Employeur EP",
                            link: "/employeurs/designation-employeur-ep/",
                        },
                        {
                            name:"Adhésion ECO+ Pro",
                            link: "/nouveaux-praticiens/adhesion-eco-pro/"
                        },
                        
                    ]
                },
                {
                    heading: "Apprentissage permanent",
                    link: "/a-propos/apprentissage-tout-au-long-de-la-vie/",
                    children:[
                        {
                            name:"Formation autochtone BEAHR",
                            link: "/nouveaux-praticiens/beahr-fr/"
                        },
                        {
                            name:"Apprentissage en direct",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-en-direct/"
                        },
                        {
                            name:"Apprentissage sur demande",
                            link: "/employeurs/formation-pour-votre-equipe/apprentissage-sur-demande/"
                        },
                        {
                            name:"Impact ECO",
                            link: "https://ecoimpact.ca/fr/"
                        },
                    ]
                },
                {
                    heading: "Accréditation",
                    link: "/educateurs/accreditation-des-programmes/",
                    children:[
                        {
                            name:"Programmes postsecondaires canadiens",
                            link: "/educateurs/accreditation-des-programmes/programmes-canadiens-actuels/"
                        },
                        {
                            name:"Accréditation internationale",
                            link: "/educateurs/accreditation-des-programmes/accreditation-des-programmes-internationaux/"
                        },
                        {
                            name:"Certification de formation",
                            link: "/educateurs/accreditation-des-programmes/accreditation-de-vos-cours-de-formation/"
                        },
                    ]
                },
                {
                    heading: "Recherche et ressources",
                    link: "/recherche-et-ressources/",
                    children:[
                        {
                            name:"Normes professionnelles nationales",
                            link: "/recherche-et-ressources/resume-des-npn/"
                        },
                        {
                            name:"Rapports de recherche",
                            link: "/recherche-et-ressources/catalogue-des-rapports-de-recherche-sur-le-marche-du-travail/"
                        },
                        {
                            name:"Annoncez votre organisation",
                            link: "/a-propos/promouvoir-votre-organisation/"
                        },
                        {
                            name:"Tableau de bord JPA",
                            link: "/recherche-et-ressources/tendances-du-marche-de-lemploi-environnemental/"
                        },
                       
                    ]
                },
               
            ]    
        },    
    ]
    
    export default menuLayoutFr;